import React from "react";
import { graphql, Link } from "gatsby";
import Layout from "../components/layout";
import Container from "../components/container";

const EventPage = ({ data }) => (
  <Layout pageTitle="Events">
    <Container verticalPadding={false} extraClasses="pv2 pv4-ns" maxWidth={1200} />
  </Layout>
);

export default EventPage;
